<template>
  <section class="hero has-background-info-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-5-desktop is-4-widescreen">
            <div class="has-text-centered pb-3" v-if="logo">
              <router-link to="/">
              <figure class="b-image-wrapper">
                <img :src="logo" loading="lazy" />
              </figure>
              </router-link>
            </div>
            <div class="box">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters({
      logo: "entitie/logo_url"
    }),
  }
}
</script>